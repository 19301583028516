import { useEffect, useState } from 'react';
import './app.scss'


function App() {

  const [endPoint, setEndPoint] = useState('')
  const [container, setContainer] = useState([])
  const [containers, setContainers] = useState([])
  const [finalpoint, setFinalPoint] = useState('')

  


  /*const url = `https://online-movie-database.p.rapidapi.com/auto-complete?q=${endPoint}`;
  const options = {
    method: 'GET',
    headers: {
      'X-RapidAPI-Key': 'd6d8670229mshe94d863a0ab80eap11b6bejsn40c37919134e',
      'X-RapidAPI-Host': 'online-movie-database.p.rapidapi.com'
    }
  };*/

  useEffect(() => {
    fetch(`https://online-movie-database.p.rapidapi.com/auto-complete?q=${finalpoint}`, {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': 'd6d8670229mshe94d863a0ab80eap11b6bejsn40c37919134e',
        'X-RapidAPI-Host': 'online-movie-database.p.rapidapi.com'
      }
    }).then(response => {
      return response.json();
    }).then(data => setContainers(data.d)).catch(error => {
      console.error(error);
    })
  },[finalpoint]);

  fetch(`https://online-movie-database.p.rapidapi.com/auto-complete?q=spi`, {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': 'd6d8670229mshe94d863a0ab80eap11b6bejsn40c37919134e',
        'X-RapidAPI-Host': 'online-movie-database.p.rapidapi.com'
      }
    }).then(response => {
      return response.json();
    }).then(data => setContainer(data.d)).catch(error => {
      console.error(error);
    })

  
  



  const submitHandler = e => {
    e.preventDefault()
    setFinalPoint(endPoint)
  }

  return (
    <div className="app">
      <form onSubmit={submitHandler} className='form'>
        <input type='text' value={endPoint} onChange={(e) => setEndPoint(e.target.value)} className='input' />
        <button className='text-red-600'>Submit</button>
      </form>
      
        <div className='list'>{containers.map((item, index) => (
          <div key={index} className='container'>
            <img src={item.i.imageUrl} alt='' />
            <div>
              <p>{item.l}</p>
              <p>{item.yr}</p>
            </div>
          </div>
        ))}</div>
        
         <div className='list'>{container.map((item, index) => (
          <div key={index} className='container'>
            <img src={item.i.imageUrl} alt='' />
            <div>
              <p>{item.l}</p>
              <p>{item.yr}</p>
            </div>
          </div>
        ))}</div>
        
     

    </div>
  );
}

export default App;
